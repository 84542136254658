html {
  box-sizing: border-box;
  background-color: black;
}

body {
  margin: 0;
  height: 100vh;
}

#root {
  height: 100%;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  font-family: monospace;
}

.canvas-container-wrapper {
  position: absolute !important;
  width: 100% !important;
  height: calc(100%) !important;
  top: 60px;
  left: 0;
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
