.data-link {
  all: unset;
  width: 100%;
  height: 100%;
  padding: 0 16px;
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

.rdt_Table {
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  background-color: transparent !important;
}

.rdt_TableHeadRow {
  /* background-color: #4a90e2; */
  color: #ffffff;
  background: gray !important;
  height: 60px;
}

.rdt_TableRow {
  /* background-color: #ffffff; */
  color: #333333;
  border-bottom: 1px solid #dddddd;
  background: #ddd !important;
}

.rdt_TableRow:hover {
  /* background-color: #f5f5f5; */
  cursor: pointer;
  background: #eee !important;
}

.rdt_TableCell {
  padding: 0 15px !important;
}

.rdt_Pagination {
  height: 60px;
  padding: 20px;
  /* background-color: #f9f9f9; */
  color: white !important;
  background: gray !important;
  border-radius: 0 0 5px 5px;
  overflow: hidden;
}

.rdt_Pagination .rdt_PaginationButton.rdt_PaginationButton_Active {
  background-color: #4a90e2;
  color: #ffffff;
}

.rdt_Pagination button {
  background-color: transparent;
  color: white !important;
  border: none;
}

.rdt_Pagination svg {
  fill: white;
}

.rdt_Pagination button:hover {
  /* background-color: #e6e6e6; */
}

.rdt_Pagination button[disabled] {
  color: #cccccc;
  > svg {
    fill: #999;
  }
}
