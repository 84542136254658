/* Styles for all scrollable elements */
::-webkit-scrollbar {
  width: 10px; /* Vertical scrollbar width */
  height: 10px; /* Horizontal scrollbar height */
  display: none;
}

::-webkit-scrollbar-thumb {
  background-color: #c1c1c1; /* Color of the scrollbar thumb */
  border-radius: 5px; /* Rounded corners for the scrollbar thumb */
  border: 2px solid #f9f9f9; /* Optional border for the thumb */
}

::-webkit-scrollbar-track {
  background-color: #f9f9f9; /* Color of the scrollbar track */
  box-shadow: inset 0 0 5px grey; /* Optional: Inner shadow for the track */
  border-radius: 0 5px 5px 0;
}

::-webkit-scrollbar-button {
  display: none; /* Hide scrollbar buttons */
}
